<template>
  <div>
    <div class="email-template">
      <div class="row">
        <div class="col-md-3" v-if="sms_templates.length > 0">
          <div
            class="nav flex-column nav-pills"
            role="tablist"
            aria-orientation="vertical"
          >
            <a
              v-for="temp in sms_templates"
              :key="temp.id"
              v-on:click.stop.prevent="temp_ = temp"
              class="nav-link"
              v-bind:class="temp_.id == temp.id ? 'active' : ''"
              href="#"
              role="tab"
              >{{ temp.name }}</a
            >
            <router-link to="/add-smstemplate" class="nav-link"
              >+ Add New Template</router-link
            >
          </div>
        </div>
        <div class="col-md-9" v-if="sms_templates.length > 0">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="v-pills-touch-tab"
            >
              <div class="email-boxs add-member add-lead">
                <textarea
                  class="form-control"
                  id="alltext"
                  v-model="temp_.body"
                  cols="30"
                  rows="10"
                ></textarea>

                <br /><br />
                <div class="form-group add-member add-lead">
                  <label>Subject</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="temp_.subject"
                  />
                </div>
                <div class="pt-3 pb-3">
                  <span style="font-weight: 700">Variables:</span>
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%CUSTOMER_NAME%', $event)"
                    >%CUSTOMER_NAME%
                  </a>
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%DEALER_NAME%', $event)"
                    >%DEALER_NAME%
                  </a>
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%VEHICLE_MAKE%', $event)"
                    >%VEHICLE_MAKE%
                  </a>
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%VEHICLE_MODEL%', $event)"
                    >%VEHICLE_MODEL%
                  </a>
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%VEHICLE_YEAR%', $event)"
                    >%VEHICLE_YEAR%
                  </a>
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%VEHICLE_TRIM%', $event)"
                    >%VEHICLE_TRIM%</a
                  >
                  <a
                    class="add-btn"
                    v-on:click="addKeyword('%SALES_PERSON%', $event)"
                    >%SALES_PERSON%</a
                  >
                  <a class="add-btn" v-on:click="addKeyword('%BDC%', $event)"
                    >%BDC%</a
                  >
                  <a
                    class="add-btn"
                    v-on:click="
                      addKeyword('%SALES_PERSON_EMAIL_SIGNATURE%', $event)
                    "
                    >%SALES_PERSON_EMAIL_SIGNATURE%</a
                  >
                </div>
                <div class="modal-footer email-footer">
                  <a
                    href="#"
                    v-on:click.stop.prevent="deleteTemplate()"
                    class="add-btn"
                  >
                    Delete</a
                  >
                  <!-- <a href="#" @click="updateTitleModal" class="rem-btn">
                    Update Template Name</a
                  > -->
                  <a
                    href="#"
                    v-on:click.stop.prevent="updateTemplate()"
                    class="rem-btn"
                  >
                    Save</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!temp_.body && loaded" class="no-template col-12">
          <h4>No Template Found</h4>
          <p>Please create your first email template.</p>
          <router-link to="/add-smstemplate" class="rem-btn"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Close.svg"
              alt=""
            />
            Add New Template</router-link
          >
        </div>
      </div>
    </div>
    <!-- Update Tile  -->
    <div
      class="modal fade"
      id="updateTitle"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update Template Title
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="updateTitle" autocomplete="off">
            <div class="modal-body">
              <div>
                <div class="add-member add-lead">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="temp_.ut_title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Update Tile  -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "Templates",
  data() {
    return {
      loaded: false,
      sms_templates: [],
      temp_: { ut_content: "", title: "" },
    };
  },
  created() {
    this.getSmsTemplates();
    this.getEmailSignature();
  },
  methods: {
    updateTitleModal() {
      if (this.temp_.ut_id) {
        $("#updateTitle").modal("show");
      }
    },
    updateTitle() {
      axios
        .post("update_template/title", this.temp_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast.open({
              position: "top-right",
              message: "Template Title Updated",
            });
            $("#updateTitle").modal("hide");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEmailSignature() {
      axios
        .get("get/email/signature", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.temp_.body = res.data.signature;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onEditorReady(CKEDITOR) {
      this.editor = CKEDITOR;
    },
    addKeyword(keyword, event) {
      console.log(event);
      // var targ = event.target || event.srcElement;
      // document.getElementById("alltext").value +=
      //   targ.textContent || targ.innerText;

      this.temp_.body += keyword;
    },
    getSmsTemplates() {
      axios
        .get("get_smstemplates", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.sms_templates = res.data.success;
            let vm = this;
            setTimeout(() => {
              if (vm.sms_templates.length > 0) {
                vm.temp_ = vm.sms_templates[0];
              }
              this.loaded = true;
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateTemplate() {
      if (this.temp_.id) {
        axios
          .post("update_smstemplate", this.temp_, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.$toast.open({
                position: "top-right",
                message: res.data.message,
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteTemplate() {
      if (this.temp_.id) {
        this.$confirm({
          title: `Are you sure?`,
          message: "You want to delete this template.",
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              axios
                .post("delete_smstemplate", this.temp_, {
                  headers: {
                    Authorization: "Bearer " + this.$storage.get("auth").token,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    this.$toast.open({
                      position: "top-right",
                      message: res.data.message,
                    });
                    if (this.sms_templates.length == 1) {
                      this.temp_ = { ut_content: "" };
                    }
                    this.getSmsTemplates();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.no-template {
  height: 300px;
  text-align: center;
  padding: 80px 30px 30px;
}
.no-template h4 {
  padding-bottom: 10px;
  color: #7b7b7b;
}
.no-template p {
  padding-bottom: 30px;
  color: #9c9c9c;
}
.no-template a {
  padding-bottom: 10px;
  display: inline-block;
}
</style>
<style>
.pb-3 .add-btn {
  text-decoration: none;
  display: inline-block;
  border: 1px solid #a2a8b8;
  padding: 8px 25px;
  border-radius: 30px;
  text-transform: capitalize;
  line-height: 1;
  color: #6d778e;
  font-size: 14px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-weight: normal;
  align-items: center;
  padding: 8px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
</style>